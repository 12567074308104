/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import CookieConsent from "react-cookie-consent";
import Header from "./header"
import Navbar from "./navBar"
import Footer from "./footer"
import Burger from "../components/burger"


const Layout = ({ children, pageInfo, lang }) => (
  <StaticQuery
    query={graphql`
query SiteTitleQuery {
  site {
    id
    siteMetadata {
      title
    }
  }
wordpressAcfOptions {
    options {
      tel_en
      tel_pl
      mail
      adres
    }
  }
}


    `}
    render={data => (
      <>  
      <Header></Header>
      <Burger lang={lang}></Burger>
        <Container fluid className="px-0 main">
            
          <Navbar telen={data.wordpressAcfOptions.options.tel_en} telpl={data.wordpressAcfOptions.options.tel_pl} lang={lang}/>
          
                <main>{children}</main>
          <Footer adres={data.wordpressAcfOptions.options.adres} mail={data.wordpressAcfOptions.options.mail} telen={data.wordpressAcfOptions.options.tel_en} telpl={data.wordpressAcfOptions.options.tel_pl} lang={lang} pagename={pageInfo.pageName}/>
        </Container>
        {lang === 'pl' ?
        <CookieConsent
        location="bottom"
        buttonText="OK"
        buttonClasses="cookies-button"
        containerClasses="cookies-wrap"
        buttonWrapperClasses="cookies-button-one"
        contentClasses="cookies-content"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: 'black', background: '#E6D38A' , fontSize: "13px" }}
        >Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb.
        <div className='cookies-button-two'><Link to='/cookies'><button className='cookies-button'>POLITYKA PRYWATNOŚCI</button></Link></div>
        </CookieConsent>
        : 
        <CookieConsent
        location="bottom"
        buttonText="OK"
        buttonClasses="cookies-button"
        containerClasses="cookies-wrap"
        buttonWrapperClasses="cookies-button-one"
        contentClasses="cookies-content"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: 'black', background: '#E6D38A' , fontSize: "13px" }}
        >Our website uses cookies to improve your experience. These cookies are used to collect information about how you interact with our website and allow us to remember you.
        <div className='cookies-button-two'><Link to='/cookies'><button className='cookies-button'>POLITYKA PRYWATNOŚCI</button></Link></div>
        </CookieConsent>
    }
      </>

    )}
  />
)

export default Layout
